@import 'variables','reset','mixins';

html{        
  font-size:16px; 
}  
body{
  position: relative;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  background: #fff;
}

.container{
  width: 100%;
  max-width: 1200px;
  padding: 0 20px 0 20px;
  margin:0 auto;
}

main {
  @include xl-up {
    background-image: url('../images/background.svg');
    background-position-y: 655px;
    background-repeat: no-repeat;
    background-position-x: center;
  }
}

.center {
  text-align: center;
}

.containerFooter {
  width: 1709px;
  width: 100%;
  padding: 5rem 1rem;
      display: flex;
      flex-direction: column;
}

.col {
  float: left;
}

// body
.subbanner {
  font-family: $font-primary;
  background-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 86%, rgba(255,255,255,0) 87%);;
}

.subbanner div:nth-child(1) {
  margin-top: -24px;
}

.subbanner div:nth-child(2) {
  margin-top: 50px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #666666;
  width: 96%;
}

.subbanner div:nth-child(2) p {
  line-height: 40px;
}

.arrow {
  position: absolute;
  margin: auto;
  right: 0;
  padding-top: 10px;
  left: 0;
}

.statement {
  margin-bottom: 160px;
  
  @include md-down{
    display: inline-block;
    text-align: center;
    margin-bottom: 80px;
  }
}

.guide {
  margin-bottom: 0px;
}

.statement div:nth-child(5){
  margin-top: 60px !important;
}

.statement div:nth-child(6){
  margin-top: 90px !important;
}

.statement-block-info {
  width: 460px;
  height: 225px;
  background: #F26522;
  border-radius: 20px;
  color: white;
  padding: 40px;
  font-size: 20px;
  margin-top: 60px;
  float: right;

  @include xl-down{
    width: 400px;
    height: unset;
  }

  @include md-down{
    float: unset;
    width: 390px;
    display: inline-block;
    text-align: center;
  }

  @include sm-down{
    width: 100%;
  }
}

.statement-block-info p:nth-child(1) {
  font-weight: 900;
  padding: 0px;
}

.statement-block-info p {
  line-height: 24px;
  padding-top: 25px;
}

.statement-block-info p:nth-child(1) {
  font-weight: 900;
  padding: 0px;
}

.statement-block-info-gray {
  width: 460px;
  height: 225px;
  background: #F5F5F5;
  border-radius: 20px;
  color: #666666;
  padding: 40px;
  font-size: 20px;
  margin-top: 50px;
  float: right;

  @include xl-down{
    width:  400px;
    height: unset;
  }

  @include md-down{
    float: unset;
    width: 390px;
    display: inline-block;
    text-align: center;
  }

  @include sm-down{
    width: 100%;
  }
}

.statement-block-info-gray p:nth-child(1) {
  font-weight: 900;
  color:#3C3C3C;
  padding: 0px;
}

.statement-block-info-gray p {
  line-height: 24px;
  padding-top: 25px;
}

.statement-block-info-gray p:nth-child(1) {
  font-weight: 900;
}

.statement-fst {
  height: 730px;
  margin-top: 140px;
  margin-bottom: 60px;

  @include md-down {
    height: 650px;
  }
}

.statement-fst > div:nth-child(1) {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  text-align: center;
  color: #3C3C3C;
}

.statement-fst > div:nth-child(2) {
  margin-top: 220px;
  padding-left: 60px;
  padding-right: 20px;

  @include md-down {
    margin-top: 110px;
  }
}

.statement-fst > div:nth-child(2) h2{
  font-family: Poppins;
  font-size: 35px;
  color: #3C3C3C;
  text-align: left;
}

.statement-fst > div:nth-child(2) h2 span{
  font-size: 35px;
  color: #F26522;
}

.statement-fst > div:nth-child(2) > div:nth-child(2) {
  width: 80px;
  margin-top: 30px;
  border: solid 1px #F26522;
  height: 1px;
}

.statement-fst > div:nth-child(2) > div:nth-child(3) {
  margin-top: 75px;
}

// ====================

.statement-scd {
  height: 570px;
  margin-bottom: 113px;
}

.statement-scd > div:nth-child(1) {
  margin-top: 140px;
  padding-left: 60px;
  padding-right: 20px;

  @include md-down {
    margin-top: 110px;
    padding-left: 20px;
  }
}

.statement-scd > div:nth-child(1) h2{
  font-family: Poppins;
  font-size: 35px;
  color: #3C3C3C;
  line-height: 45px;

  @include md-down {
    text-align: left;
  }
}

.statement-scd > div:nth-child(1) h2 span{
  font-size: 35px;
  color: #F26522;
}

.statement-scd > div:nth-child(1) > div:nth-child(2) {
  width: 80px;
  margin-top: 30px;
  border: solid 1px #F26522;
  height: 1px;
}

.statement-scd > div:nth-child(1) > div:nth-child(3) {
  margin-top: 75px;
}

// ====================

.guide {
  @include xl-down{
    display: inline-block;
    text-align: center;
  }
}

.guide .statement-scd {
  height: 670px;
}

.guide img {
  @include sm-down {
    width: 100%;
  }
}

.guidelines {
  margin-top: 75px;

  @include xl-down {
    margin-top: 30px;
    min-height: 90px;
  }
}

.guidelines > div:nth-child(1) {
  float: left;
  min-height: 110px;
}

.guidelines > div:nth-child(1) div {
  padding: 20px 30px 20px 30px;
  margin: 8px 15px;
  background-color: #650787;
  color: #F26522;
  border-radius: 8px;
  font-size: 40px;
}

.guidelines > div:nth-child(2) {
  font-size: 16px;
  line-height: 28px;
}

// ==================

.facts {
  margin-top: 70px;
  margin-bottom: 20px;
  display: inline-block;

  @include lg-down{
    margin-top: 60px;
  }
}

.facts > div:nth-child(1) {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 57px;
  text-align: center;
  color: #3C3C3C;
  margin-bottom: 60px;
}

.facts > div:nth-child(1) span {
  color: #F26522;
}

.facts .large-6 {
  background: #F5F5F5;
  border-radius: 8px;
}

.facts-sub {
  display: inline-flex;
  @include md-down{
    display: unset;
  }
}

.facts-sub > div {
  padding: 30px 30px 30px 55px;
  height: 714px;
  width: 45%;
  margin: auto;

  @include md-down{
    width: 100%;
    height: unset;
    margin-bottom: 20px;
  }
}

.facts-sub > div:nth-child(1) div {
  min-height: 75px;
  padding-top: 20px;
  line-height: 25px;

  @include md-down {
    min-height: 40px;
  }
}

.facts-sub > div:nth-child(1) span {
  padding-right: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #F26522;
}

.facts-sub > div:nth-child(2) p {
  line-height: 30px;
}

.facts-items {
  margin: 25px 0px 30px 0px;
  display: flex;
  
  @include lg-down {
    margin: 5px 0px 15px 0px;
  }

  @include md-down {
    margin: 0;
  }

}

.facts-items > div:nth-child(1) {
  height: 100%;
  padding: 10px 10px;
}

.facts-items > div:nth-child(2) {
  line-height: 30px;
}

.large-12 {
  width: 100%;
}

.large-6 {
  width: 50%;
}

.xlarge-12{
  @include xl-down{
    width: 100%;
  }
}

.small-12 {
  @include md-down{
    width: 100%;
  }
}
