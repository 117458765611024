$LaranjaPottencial: #f26522;

#main-menu {
  background-color: fade-out(black, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
    opacity: 0;
    transition: opacity time(default) ease(inout);
  }

  .wrapper {
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 220px;
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 50px 0 0 30px;
    transform: translateX(100%);
    overflow-y: scroll;
    transition: transform time(default) ease(inout);

    .navigation {
      > ul {
        > li {
          position: relative;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &.active {
            &::before {
              content: '';
              background-color: color(orange);
              position: absolute;
              top: 50%;
              bottom: 0;
              right: calc(100% + 12px);
              z-index: 1;
              width: 4px;
              height: 30px;
              transform: translateY(-50%);
              display: none;
            }
          }

          a,
          span {
            color: #333333;
            font-size: 16px;
            font-weight: normal;
          }

          ul {
            padding: 15px 15px 0 15px;

            li {
              &:not(:last-child) {
                margin-bottom: 10px;
              }

              a {
                font-size: 12px;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }

    .phones {
      margin-top: 20px;
      padding-right: 15px;

      .phone {
        &:not(:last-child) {
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid fade-out(black, 0.9);
        }

        a {
          color: #7d7d7d;
          font-size: 16px;
          font-weight: normal;
          display: flex;
          align-items: center;

          img {
            position: relative;
            top: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .wrapper {
      transform: translateX(0);
    }
  }
}

#mobile-menu {
  background-color: fade-out(black, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  font-family: 'Open Sans', sans-serif;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  .content-menu {
    background-color: #fff;
    width: 335px;
    max-width: 100%;
    margin-left: auto;
    height: 100%;
    .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
    }
    .navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          display: block;
          button {
            font-weight: bold;
            text-transform: uppercase;
            color: #101010;
            padding: 15px 0;
            display: block;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          a {
            display: flex;
            align-items: center;
            color: #000000;
            padding: 12px 0 12px 0;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          ul {
            display: none;
            &.dropActiveOne,
            &.dropActiveTwo {
              display: block !important;
            }
            li {
              a {
                text-transform: unset;
                font-weight: normal;
                padding: 12px 0 12px 21px;
                img {
                  width: 22px;
                  margin-bottom: 8px;
                  margin-right: 12px;
                }
                &:after {
                  content: '';
                }
              }
            }
          }
        }
      }
    }
    .login2-navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          display: block;
          button {
            font-weight: bold;
            text-transform: uppercase;
            color: #f26522;
            padding: 15px 0;
            display: block;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          a {
            display: flex;
            align-items: center;
            color: #000000;
            padding: 12px 0 12px 0;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          ul {
            display: none;
            &.dropActiveOne,
            &.dropActiveTwo {
              display: block !important;
            }
            li {
              a {
                text-transform: unset;
                font-weight: normal;
                padding: 12px 0 12px 21px;
                img {
                  width: 22px;
                  margin-bottom: 8px;
                  margin-right: 12px;
                }
                &:after {
                  content: '';
                }
              }
            }
          }
        }
      }
    }

    .login-navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          a {
            color: $LaranjaPottencial;
            display: block;
            font-weight: bold;
            padding: 15px 0;
            font-size: 12px;
          }
        }
      }
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .wrapper {
      transform: translateX(0);
    }
  }
}
