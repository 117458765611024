@import 'variables','mixins';

.item{
  padding-bottom: 40px;
  word-break: break-word;
  border-radius:15px;
  
  .title{
    color: #3C3C3C;
    position: relative;
    margin: 0 0 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    
    @include fontSize(24);
    &:after{
      content: "";
      background-color: $color-primary;
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 85px;
      height: 2px;
    }
  }
  
  .image-container{
    margin: 30px auto;
    max-width: 90%;
    display:block;
    @include md-down{
      max-width: 100%;
    }
    img{
      display:block;
      margin: 0 auto;
      max-width: 100%;
    }
    .imageCaption {
      @include fontSize(18);
      position: relative;
      padding-left: 30px;
      margin-bottom: 0;
      &:before{
        content: '';
        background: url('../images/ico-check.svg') no-repeat center;
        width: 20px;
        height: 20px;
        left: 0;
        top: 4px;
        position: absolute;
      }
    }
  }
}