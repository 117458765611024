#main-footer {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  position: relative;
  width: 100vw;
  //padding: 65px 0;
  overflow: hidden;
  display: flex;

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    left: 31px;
    align-items: flex-start;
    justify-content: left;
    display: flex;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 0 40px;
      flex-wrap: wrap;
    }

    .left {
      flex-shrink: 0;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
      }

      &-logo {
        position: relative;

        a {
          display: inline-block;
        }
      }

      &-social {
        margin-top: 75px;

        ul {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 0px;
              /** Mobile. */
              @media (max-width: 1023px) {
                margin-right: 2px;
              }
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 42.5px;
              height: 42.5px;
              border-radius: 4px;
              transition: background-color time(default) ease(inout);

              &:hover {
                background-color: color(grey, dark);
              }
            }
          }
        }
      }
    }

    .containerFooter {
      width: 1709px;
      width: 100%;
      padding: 5rem 1rem;
          display: flex;
          flex-direction: column;
    }

    .center {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin-right: 65px;
      margin-left: 40px;
      text-align: left;

      /** Mobile. */
      @media (max-width: 1100px) {
        margin-right: 60px;
        margin-left: 50px;
      }
      
      @media (max-width: 1023px) {
        width: 50%;
        margin: 52px 0;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin: 52px 0;
      }

      &-title {
        font: normal normal 700 14px/20px Open Sans;
        color: #3c3c3c;
        //font-size: 16px;
        //font-weight: 600;
        //padding-left: 30px;
        margin-bottom: 20px;

         /** Mobile. */
      @media (max-width: 1372px) {
        padding-top: 30px;
      }
      }

      .item {
        position: relative;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
          text-indent: -9999px;
        }

        &-icon {
          width: 30px;
          flex-shrink: 0;
        }

        &-title {
          color: #3c3c3c;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          transition: color time(default) ease(inout);
        }

        &:hover .item-title {
          color: color(grey, dark);
        }
      }
    }

    .right {
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;

      /** Mobile. */
      @media (max-width: 1024px) {
        width: 100%;
        flex-wrap: wrap;
        padding-top: 30px;
      }

      .column {
        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 45px;

          /** Mobile. */
          @media (max-width: 1100px) {
            margin-right: 50px;
          }
          @media (max-width: 1023px) {
            margin: 0 0 33px;
          }
        }

        &-title {
          font: normal normal bold 14px/83px Open Sans;
          letter-spacing: 0px;
          color: #3c3c3c;
          opacity: 1;
          //color: #3C3C3C;
          font-size: 14px;
          // font-weight: 300;
          text-transform: uppercase;
          //margin-bottom: 15px;
        }

        &-list {
          ul li {
            &:not(:last-child) {
              margin-bottom: 12px;
            }

            a {
              color: #3c3c3c;
              font-size: 16px;
              font-weight: normal;
              transition: color time(default) ease(inout);

              &:hover {
                color: color(grey, dark);
              }
            }
          }
        }
      }
    }

    .info {
      top: 4690px;
      left: 31px;
      font: normal normal normal 14px/35px Open Sans;
      margin-top: 60px;
      margin-left: 31px;
      color: #3c3c3c;
      line-height: 19px;
      //text-transform: capitalize;
      opacity: 1;
      display: flex;
    }
  }
}
