// TAMANHO PADRÃO 
$size-default:16;

// RESOLUÇÕES
$xl-width: 1200px;
$lg-width: 992px;
$md-width: 768px;
$sm-width: 576px;

// FONT-SIZE
@mixin fontSize($size) {
  font-size: $size/$size-default+rem
}  

// ANIMAÇÕES
@mixin addEase ($prop: all, $time:.4s, $type: ease){
  transition: $prop $time $type;
}

// ATÉ SM
@mixin sm-down {
  @media (max-width: $sm-width - 1) {
    @content;
  }
}

// ACIMA DE SM
@mixin sm-up {
  @media (min-width: $sm-width) {
    @content;
  }
}

// ATÉ MD
@mixin md-down {
  @media (max-width: $md-width - 1) {
    @content;
  }
}

// ACIMA DE MD
@mixin md-up {
  @media (min-width: $md-width) {
    @content;
  }
}

// ATÉ LG
@mixin lg-down {
  @media (max-width: $lg-width - 1) {
    @content;
  }
}

// ACIMA DE LG
@mixin lg-up {
  @media (min-width: $lg-width) {
    @content;
  }
}

// ATÉ XL
@mixin xl-down {
  @media (max-width: $xl-width - 1) {
    @content;
  }
}

// ACIMA DE XL
@mixin xl-up {
  @media (min-width: $xl-width) {
    @content;
  }
}