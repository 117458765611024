@import 'variables', 'mixins';

$LaranjaPottencial: #f26522;

@keyframes slidein {
  from {
    top: -184px;
    width: 150%;
  }

  to {
    top: 0;
    width: 100%;
  }
}

@keyframes dropSiledIn {
  from {
    margin-top: 15px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes loginInFade {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

header{
  background: $color-primary;
  position: sticky;
  top:0;
  z-index: 9;
}

.optionsContainer{
  position: sticky;
  top:67px;
  background-color: #FFF;
  padding: $gutter 0;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  z-index: 9;
  margin-bottom: $gutter*2;

  .lettersContainer{
    margin: 0 -5px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    @include sm-up{
      flex-wrap: wrap;
    }
    button{
      background-color: #f5f5f5;
      border-radius: 6px;
      width: calc(100%/26 - 10px);
      height: 35px;
      font-size: 16px;
      text-transform: uppercase;
      border: 0 none;
      margin: 5px;
      &:not(:disabled){
        cursor: pointer;
        color: $color-primary;
        @include addEase();
        display: block;
        &:hover{
          background-color: #e9e9e9;
        }
      }
      @include lg-down{
        width: calc(100%/13 - 10px);
      }
      @include sm-down{
        display: none;
        min-width: 35px
      }
    }
  }
}

.banner{
  background: url('../images/bg-banner.jpg') no-repeat center;
  background-size: cover;
  padding: 200px 0 200px;
 
  h1{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left; 
    @include fontSize(72);
  }


  h1:nth-of-type(2n+2) {
    color: #F26522;
    font-size: 85px;
    font-weight: bold;
    line-height: 130px;
  }

  @include md-down{
    padding: 70px 0 40px;
    h1{
      padding: 0;
      @include fontSize(52);
    }
  }
  @include sm-down{
    padding: 190px 0 20px;
    h1{
      @include fontSize(24);
    }
    
    h1:nth-of-type(2n+1) {
      font-size: 2.5rem;
    }

    h1:nth-of-type(2n+2) {
      color: #F26522;
      font-size: 50px;
      font-weight: bold;
      line-height: 65px;
    }
  }
}

.banner-glossary{
  background: url('../images/bg-glossary.jpg') no-repeat center;
  background-size: cover;
  background-position: revert;

  h1{
    text-align: left; 
    font-size: 63px;
    line-height: 75px;
  }
}

#main-header {
  background-color: #fff;
  z-index: 50;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation-duration: 1s;
    animation-name: slidein;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    .logo a {
      display: inline-block;

      img {
        width: 170px;
      }

      /** Mobile. */
      @media (max-width: 1023px) {
        img {
          width: 150px;
          height: auto;
        }
      }
    }

    .menu-toggler {
      display: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: block;
      }
    }

    .right {
      display: flex;
      align-items: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      .navigation {
        position: relative;
        top: -1px;
        margin-right: 45px;

        > ul {
          display: flex;
          align-items: center;

          > li {
            &:not(:last-child) {
              margin-right: 30px;
            }

            span {
              color: white;
              font-size: 14px;
              font-weight: normal;
              display: inline-block;
            }

            > a {
              color: white;
              font-size: 14px;
              font-weight: normal;
              padding-bottom: 10px;
              position: relative;

              &::after {
                content: '';
                background-color: white;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 2px;
                transform-origin: 50% 50%;
                transform: scaleX(0);
                transition: transform .7s cubic-bezier(.5,0,0,1);
              }

              &:hover {
                &::after {
                  transform: scaleX(1);
                }
              }
            }

            &.has-children {
              position: relative;
              display: flex;
              align-items: center;
              bottom: -2px;

              img {
                display: inline-block;
                margin-left: 10px;
              }

              .submenu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                opacity: 0;
                visibility: hidden;
                transform: translateY(10px);
                transition-property: opacity,visibility,transform;
                transition-duration: .7s;
                transition-timing-function: cubic-bezier(.5,0,0,1);
                display: flex;
                justify-content: center;
                pointer-events: none;
                padding-top: 30px;

                ul {
                  background-color: #f5f5f5;
                  white-space: nowrap;
                  position: relative;
                  border-radius: 5px;

                  &::before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: calc(50% - 6px);
                    width: 1px;
                    height: 1px;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid white;
                    border-left: 6px solid transparent;
                  }

                  li {
                    white-space: nowrap;

                    a {
                      color: #666;
                      font-size: 14px;
                      font-weight: normal;
                      display: block;
                      height: 38px;
                      display: flex;
                      align-items: center;
                      padding: 1px 40px 0 15px;
                      transition-property: color,background-color;
                      transition-duration: .7s;
                      transition-timing-function: cubic-bezier(.5,0,0,1);

                      &:hover {
                        background-color: rgba(242, 101, 34, 0.1);
                        color: color(grey, dark);
                      }
                    }
                  }
                }
              }

              &:hover .submenu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: initial;
              }
            }
          }
        }
      }

      .phones {
        display: flex;
        align-items: center;

        img {
          display: inline-block;
        }

        a {
          color: white;
          font-size: 14px;
          font-weight: normal;
          padding: 0 10px;
          transition: opacity .7s cubic-bezier(.5,0,0,1);

          &:nth-child(2) {
            border-right: 1px solid white;
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}


#new-main-header {
  position: absolute;
  z-index: 9;
  width: 100%;
  .NewHeader {
    position: absolute;
    z-index: 9;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    .Container {
      position: relative;
      width: 100%;
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
      .MenuLeft {
        display: flex;
        align-items: center;
        ul {
          display: flex;
          padding-left: 500px;
          li {
            padding: 20px 20px 20px;
            cursor: pointer;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            a {
              color: #fff;
            }
            .MenuArrowDown {
              width: 16px;
              margin-left: 5px;
              transition: all 0.5s ease-in-out;
            }
            .MenuArrowDownBlack {
              //width: 16px;
              margin-left: 5px;
              transition: all 0.5s ease-in-out;
            }
            ul {
              display: none;
              position: absolute;
              left: 0;
              top: 70%;
              opacity: 0;
              visibility: hidden;
            }
            &:hover {
              &:after {
                content: '';
                background-color: #fff;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 6px;
                transform-origin: 50% 50%;
                transform: scaleX(0);
                transition: transform ease-in-out;
              }
              .MenuArrowDown {
                transform: rotate(-90deg);
              }
              .MenuArrowDownBlack {
                transform: rotate(-90deg);
              }
              ul {
                display: block;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: initial;
              }
            }
          }
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }

      .SubMenuContainer {
        display: flex;
        left: 0;
        z-index: 3;
        width: 100%;

        animation-duration: 1s;
        animation-name: dropSiledIn;
        .SubMenuContent {
          width: 100%;
          display: flex;
          background-color: #fff;
          border: 3px solid $LaranjaPottencial;
          border-radius: 12px;
        }

        .SubMenuList {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-right: 1px solid rgba(0, 0, 0, 0.29);
          margin: 41px 18px 29px 0;
          width: 35%;
        }

        .SubMenuItem {
          display: flex;
          align-items: center;
          padding: 10px 20px 10px 20px;
          width: 100%;
          justify-content: space-between;
          h4 {
            color: #0b0b0b;
          }
          span {
            display: flex;
            align-items: center;
          }
          img {
            width: 22px;
            margin-right: 10px;
            margin-bottom: 5px;
          }
          &:after {
            content: '❯';
            width: 0.75em;
            height: 0.75em;
            text-align: center;
          }
          &:hover {
            background-color: rgba(242, 101, 34, 0.05);
          }
        }

        .SubMenuMedia {
          position: relative;
          margin: 55px 27px 35px 0;
          display: flex;
        }

        .SubMenuMediaText {
          position: absolute;
          bottom: 0;
          left: 0;
          color: #fff;
          display: flex;
          align-items: center;
          padding: 23px 33px;
          h3 {
            font-size: 27px;
            margin-bottom: 12px;
          }
          p {
            font-size: 14px;
            line-height: 19px;
            color: #fff;
          }
          span {
            flex: 1;
          }
          a {
            font-size: 20px;
            background-color: #fff;
            color: $LaranjaPottencial !important;
            padding: 8px 37px;
            border-radius: 18px;
            width: 179px;
            display: block;
            margin-left: 20px;
            margin-bottom: 10px;
          }
        }

        .ImgMedia {
          width: auto;
          object-fit: cover;
          border-radius: 10px;
          //width: 100%;
          //height: 100%;
        }

        .footer {
          margin: 10px 15px;
          border-top: 1px solid rgba(#707070, 0.39);
          padding: 2rem 0 0 0;
          h4 {
            color: #0b0b0b;
            font-size: 12px;
            margin: 0 0 2rem;
          }
          .linksButton {
            display: flex;
            .iconButton {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 1px solid $LaranjaPottencial;
              background-color: #f5f5f5;
              border-radius: 6px;
              padding: 10px;
              margin: 0 10px 0 0;
              width: 140px !important;
              min-height: 84px;
              img {
                width: 24px;
              }
              p {
                font-size: 11px;
                margin-top: 8px;
                font-weight: bold;
                text-align: center;
                line-height: 14px;
                color: #0b0b0b;
              }
            }
          }
          .SignupFooterMenu {
            display: block;
            padding: 5px 10px;
            font-size: 12px;
            margin: 2rem 0 10px 0;
            color: #0b0b0b;
            text-decoration: underline;
            text-decoration-color: $LaranjaPottencial;
            a {
              color: $LaranjaPottencial;
              font-weight: 600;
            }
          }
        }
      }

      .MenuRight {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        padding: 4px 0;
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .LinkRight {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .ButtonMenuRight {
        font-size: 12px;
        font-weight: 600;
        background-color: #fff;
        color: $LaranjaPottencial !important;
        border: 2px solid $LaranjaPottencial;
        padding: 12px 29px;
        border-radius: 18px;
        display: block;
        margin-left: 20px;
        text-transform: uppercase;
      }
      ul.loginMenu {
        li {
          .buttonLogin {
            font-size: 12px;
            font-weight: 600;
            background: rgba(255, 255, 255, 0.366);
            border-radius: 30px;
            border: 2px solid transparent;
            padding: 12px 29px;
            display: block;
            margin-left: 20px;
            text-transform: uppercase;
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            span {
              color: #ffffff;
            }
            &:hover {
              background-color: #fff;
              color: $LaranjaPottencial !important;
              border: 2px solid $LaranjaPottencial;
              span {
                color: $LaranjaPottencial;
              }
            }
          }
          ul {
            display: none;
            animation-duration: 0.3s;
            animation-name: loginInFade;
          }
          &:hover {
            ul {
              display: block;
            }
          }
        }
      }

      .LoginDropMenu {
        position: absolute;
        top: 97%;
        right: 0;
        background-color: #fff;
        border-top: 2px solid $LaranjaPottencial;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        width: 247px;
        padding: 10px;
        a {
          img {
            margin-right: 10px;
          }
        }
        h4 {
          font-size: 12px;
          text-align: center;
          margin: 15px 0;
        }
      }

      .LinkDropMenu {
        font-size: 12px;
        padding: 12px 5px;
        cursor: pointer;
        color: #0b0b0b;
        &:hover {
          background-color: rgba(242, 101, 34, 0.05);
        }
      }

      .SignupDropMenu {
        border-bottom: 1px solid $LaranjaPottencial;
        padding: 5px 10px;
        font-size: 12px;
        margin: 20px 0 15px 0;
        a {
          color: $LaranjaPottencial;
          font-weight: 600;
        }
      }
      .menu-toggler {
        color: #fff;
        display: flex;
        align-items: center;
        span {
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
        }
        img {
          height: 13px;
          margin-left: 1rem;
        }
      }
    }
    &.menuWhite {
      background-color: #fff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.29);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      animation-duration: 1s;
      animation-name: slidein;

      // transition: slidein 0.5s linear;
      // transform: scaleY(1);
      .Container {
        padding: 1rem 2rem;
        // @media (max-width: 641px) {
        //   padding: 2rem 2rem;
        // }
        .MenuLeft {
          ul {
            li {
              color: #010101;
              a {
                color: #010101;
              }
              &:hover {
                &:after {
                  background-color: $LaranjaPottencial;
                }
              }
            }
          }
        }
        .LinkRight {
          color: $LaranjaPottencial;
        }
        .menu-toggler {
          span {
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
          }

          /** Mobile. */
          @media (max-width: 1023px) {
            display: block;
            span {
              color: #f26522;
            }
          }
        }
        ul.loginMenu {
          li {
            .buttonLogin {
              background-color: #fff;
              color: $LaranjaPottencial !important;
              border: 2px solid $LaranjaPottencial;
              span {
                color: $LaranjaPottencial;
              }
            }
          }
        }
      }
    }
  }
}
