// CORES
$bg-default: #f6f6f6;
$color-primary: #F26522;

// ESPAÇAMENTO
$gutter: 15px;

// FONTES
$font-primary: 'Poppins', sans-serif;
$font-secundary: 'Open Sans', sans-serif;
$font-terciary: 'Inter', sans-serif;